import { useEffect } from 'react';
import { useForm } from '@mantine/form';
import { Checkbox, FormHelperText, FormInput, FormLabel } from '../../../core';
import useNewsLetterSignup from '@ui/hooks/useNewsletterSignup';
import { Button } from "@ui/components/core/button"
import { toast } from '@ui/hooks/use-toast';

type NewsletterFormProps = {
  onSuccess?: () => void;
};

const NewsLetterForm = ({ onSuccess }: NewsletterFormProps) => {
  const { isLoading, data, error, submit } = useNewsLetterSignup();

  const { reset, ...form } = useForm({
    initialValues: {
      email: '',
      terms: false,
    },
    validate: {
      email: (value) => {
        if (value.length === 0) return 'Email is required.';
        if (!/^\S+@\S+$/.test(value)) return 'Invalid email format.';
        return null;
      },
      terms: (value) => (value === false ? 'You must accept the terms and conditions' : null),
    },
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  });

  useEffect(() => {
    if (!data) return;
    reset();
    onSuccess?.();
  }, [data, reset, onSuccess]);

  useEffect(() => {
    if (!error) return;

    toast({
      description: error,
      variant: 'destructive',
    })
  }, [error]);

  return (
    <form noValidate onSubmit={form.onSubmit((values) => submit({ email: values.email }))}>
      <div className="max-w-[38rem] space-y-5">
        <div className="relative pb-5">
          <div className="flex items-start space-x-2 md:space-x-4">
            <div className="w-3/4">
              <FormLabel htmlFor="newsletterFormEmail" required>
                Email
              </FormLabel>

              <FormInput
                {...form.getInputProps('email', { withError: false })}
                className="w-full"
                id="newsletterFormEmail"
                name="email"
                autoComplete="email"
                placeholder="Enter your email address"
                invalid={Boolean(form.errors.email)}
              />
            </div>
            <div className="w-1/4 mt-6">
              <Button className="w-full whitespace-nowrap" type="submit" loading={isLoading}>
                SIGN UP
              </Button>
            </div>
          </div>
          {form.errors.email && (
            <FormHelperText className="absolute bottom-0" invalid>
              {form.errors.email}
            </FormHelperText>
          )}
        </div>
        <div className="relative pb-5">
          <Checkbox
            checked={form.values.terms}
            onChange={(e) => form.setFieldValue('terms', e.target.checked)}
            label="I consent to receive promotional communications related to my purchase history, website browsing activity, and/or engagement with marketing materials."
            size="xs"
          />

          {form.errors.terms && (
            <FormHelperText className="absolute bottom-0" invalid>
              {form.errors.terms}
            </FormHelperText>
          )}
        </div>
      </div>
    </form>
  );
};

export default NewsLetterForm;
