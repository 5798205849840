import React from 'react';
import Image from '@ui/components/core/image';

type GiftCardIconProps = {
  height: number;
  width: number;
};

const GiftCardIcon = ({ height, width }: GiftCardIconProps): React.ReactElement => (
  <Image src="/common/giftCardIcon.svg" alt="Hello Molly Gift Card Icon" height={height} width={width} />
);

export default GiftCardIcon;
