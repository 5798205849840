import { type ModalProps } from '@mantine/core';
import Image from '@ui/components/core/image';
import cn from '@ui/utils/cn';
import Dialog from '@ui/components/core/dialog';

const NewsletterModal = ({ opened, onClose }: ModalProps) => {
  return (
    <>
      <Dialog.Root open={opened} onOpenChange={onClose}>
        <Dialog.Content className='items-center rounded-lg w-full max-w-[750px] h-[97dvh] md:h-[750px]' classNames={
          {close:'z-[10] text-white'}
        }
        aria-describedby='newsletter-dialog'
        >
          <Dialog.Title className="sr-only">Newsletter Modal</Dialog.Title>
          <Image
            src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Newsletter-confirmation.jpg?v=1709303804"
            className="w-full h-full object-top object-cover absolute brightness-50"
            width={2185}
            height={3277}
            alt=""
          />
          <div className="w-full flex flex-col items-center justify-center relative py-6 md:pt-8 md:pb-16 h-full">
            <Image
              src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/popup---welcome.webp?v=1709304095"
              className="w-[188px] md:w-[350px] h-auto mx-auto"
              width={761}
              height={151}
              alt=""
            />
            <div className="relative w-[90%] md:w-[80%] mx-auto mt-10">
              <Image
                src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/popup---oval_4bd4797c-f2ab-4270-a800-2f546bb76ce5.png?v=1709304096"
                className="w-full h-full absolute"
                width={1686}
                height={1122}
                alt=""
              />
              <div
                className={cn(
                  'relative space-y-[15px] md:space-y-5 pt-12 pb-6 md:pt-24 md:pb-16',
                  'text-center text-[14px] md:text-xl text-white uppercase font-light',
                  '[&_*]:leading-[25px] md:[&_*]:leading-10 [&_*]:tracking-[0.65px]',
                  '[&_b]:font-bold',
                )}
              >
                <p>
                  Enter this <b>special code</b> at checkout <br />
                  for <b>10% Off</b> your order. <br />
                  We&apos;ve also sent it to your email.
                </p>
                <div className="text-[24px] md:text-[30px] font-bold">CODE: SIGNUPSQUAD</div>
                <p>Happy Shopping!</p>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export default NewsletterModal;
