import React from 'react';
import { NewsletterForm } from '../../../shared';
import Image from '@ui/components/core/image';
import NewsletterModal from '@ui/components/shared/modals/NewsletterModal/NewsletterModal';
import { useDisclosure } from '@mantine/hooks';

const NewsletterSection = (): React.ReactElement => {
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure();

  const banners = {
    desktop: {
      url: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Sign_up_banner-desktop.jpg?v=1716856080',
      width: 2880,
      height: 350,
    },
    mobile: {
      url: 'https://cdn.shopify.com/s/files/1/0310/0436/0837/files/Sign_up_banner-mobile.jpg?v=1716856142',
      width: 992,
      height: 350,
    },
  }

  return (
    <div className="border-y-[0.6px] border-black relative isolate" id="newsletter-section">
      <div className='hidden md:block'>
        <Image
          src={banners.desktop.url}
          className="w-full h-full object-cover object-top absolute"
          width={banners.desktop.width}
          height={banners.desktop.height}
          alt="Newsletter Banner Background"
        />
        <div className="absolute h-full w-1/2 bg-gradient-to-r from-white to-transparent"></div>
      </div>
      <div className="md:hidden">
        <Image
          src={banners.mobile.url}
          alt="Newsletter Banner Background"
          className="w-full h-full object-cover object-top absolute"
          width={banners.mobile.width}
          height={banners.mobile.height}
          srcSet={`${banners.mobile.url} 320w, ${banners.mobile.url} 480w, ${banners.mobile.url} 800w`}
        />
        <div className="absolute h-[71%] w-full bottom-0 bg-gradient-to-t from-white to-transparent"></div>
      </div>
      <div className="relative mx-auto w-full max-w-[34rem] px-4 py-8">
        <div className="font-bold mb-4 tracking-[0.08px] text-center md:text-left">SIGN UP FOR 10% OFF</div>
        <NewsletterForm onSuccess={openModal} />
        <NewsletterModal opened={modalOpened} onClose={closeModal} />
      </div>
    </div>
  );
};

export default NewsletterSection;
