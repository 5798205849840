import Image from '@ui/components/core/image';

type USFlagIconProps = {
  height: number;
  width: number;
};

const USFlagIcon = ({ height, width }: USFlagIconProps) => (
  <Image src={'/region/USFlag.svg'} alt="Hello Molly AU Flag Icon" height={height} width={width} />
);

export default USFlagIcon;
