import React from 'react';
import {
  Container,
  Divider,
  GiftCardIcon,
  Group,
  Logo,
  NextLink,
} from '../../../core';
import { SubTitle } from '../../typography';
import CountrySelector from '../../selectors/CountrySelector/CountrySelector';
import { FooterMenuRoutes } from '../../../../types/routing/FooterMenuRoutingTable.type';
import Image from '@ui/components/core/image';

type FooterMenuProps = {
  menuHeadingLabels: Array<string>;
  menuLabels: { [key: string]: Array<string> };
};

function setLocaleYear(): string {
  return new Intl.DateTimeFormat().formatToParts(new Date())?.find(({type}) => type === "year")?.value ?? "";
};

const FooterMenu = ({ menuHeadingLabels, menuLabels }: FooterMenuProps): React.ReactElement => {

  const socialLinks = (
    <>
      <a href="https://tiktok.com/@hellomolly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-tiktok.png?v=1700115989"
          alt="Hello Molly on Tiktok"
          width="224"
          height="240"
        />
      </a>
      <a href="https://instagram.com/hellomolly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-instagram.png?v=1700115989"
          alt="Hello Molly on Instagram"
          width="241"
          height="240"
        />
      </a>
      <a href="https://www.facebook.com/hellomolly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-facebook.png?v=1700115988"
          alt="Hello Molly on Facebook"
          width="127"
          height="240"
        />
      </a>
      <a href="https://www.pinterest.com/hellomollyfshn/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-pinterest.png?v=1700115988"
          alt="Hello Molly on Pinterest"
          width="207"
          height="253"
        />
      </a>
      <a href="https://www.youtube.com/user/HelloMollyFashion/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-youtube.png?v=1700115987"
          alt="Hello Molly on Youtube"
          width="331"
          height="240"
        />
      </a>
      <a href="https://www.linkedin.com/company/hello-molly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-linkedin.png?v=1700115988"
          alt="Hello Molly on Linked In"
          width="254"
          height="240"
        />
      </a>
      <a href="https://twitter.com/LoveHellomolly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-twitter.png?v=1700115987"
          alt="Hello Molly on Twitter"
          width="241"
          height="220"
        />
      </a>
      <a href="https://threads.net/hellomolly/" target="_blank" rel="noreferrer">
        <Image
          style={{ width: 'auto', height: '24px' }}
          src="https://cdn.shopify.com/s/files/1/0310/0436/0837/files/email-social-icon-threads.png?v=1700115987"
          alt="Hello Molly on Threads"
          width="211"
          height="244"
        />
      </a>
    </>
  );

  return (
    <>
      <div className="hidden md:block">
        <div className="bg-pink-3">
          <Container py="3rem">
            <Group align="flex-start" position="apart">
              <div className='flex gap-12'>
                {menuHeadingLabels.map((menuHeadingLabel) => (
                  <div key={`${menuHeadingLabel}-footer-menu-heading`} className='flex flex-col gap-3 w-[200px]'>
                    <h3 className='text-sm font-bold'>
                      {menuHeadingLabel}
                    </h3>
                    {menuLabels[menuHeadingLabel].map((menuLabel) => (
                      <div key={`${menuLabel}-footer-menu-pagelink`}>
                        {menuLabel === 'Gift Cards' ? (
                          <Group spacing="xs" py="1rem">
                            <GiftCardIcon height={30} width={30} />
                            <NextLink
                              style={{ letterSpacing: 0 }}
                              label={menuLabel}
                              href="/products/gift-card/"
                              variant="underline"
                              bold
                            />
                          </Group>
                        ) : (
                          <div>
                            <NextLink
                              style={{ letterSpacing: 0 }}
                              label={menuLabel}
                              href={FooterMenuRoutes[menuLabel.toUpperCase() as keyof typeof FooterMenuRoutes] || '#'}
                              variant="underline"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className='flex flex-col items-center justify-center gap-4'>
                <CountrySelector className='gap-4 items-center justify-center' />
                <Group py="xl" position="center" spacing="2rem">
                  {socialLinks}
                </Group>
              </div>
            </Group>
          </Container>
        </div>
        <div className="bg-brand-lightpink2 border-t-[0.6px] border-black">
          <Container>
            <div className="flex relative items-end justify-center pt-4 pb-2">
              <div className="flex items-end absolute left-0">
                <Logo height={35} width={100} className="mr-8" />
                <NextLink
                  label="Terms & Conditions"
                  href="/pages/terms-and-conditions/"
                  variant="underline"
                  className="tracking-[0em]"
                />
                <span className="mx-4 text-[14px]">|</span>
                <NextLink
                  label="Privacy Policy"
                  href="/pages/privacy-policy/"
                  variant="underline"
                  className="tracking-[0em]"
                />
              </div>
              <div className="text-[12px] tracking-[-0.06px]">© {setLocaleYear()} Hello Molly. All Rights Reserved</div>
            </div>
          </Container>
        </div>
      </div>
      <div className="md:hidden">
        <div className="bg-brand-lightpink">
          <Container className="px-4 py-8">
            <div className="grid grid-cols-2 gap-9">
              {menuHeadingLabels.map((menuHeadingLabel) => (
                <div key={menuHeadingLabel}>
                  <SubTitle className="font-bold text-[14px] mb-[22px]">{menuHeadingLabel}</SubTitle>
                  {menuLabels[menuHeadingLabel]
                    .filter((menuLabel) => menuLabel !== 'Gift Cards')
                    .map((menuLabel) => (
                      <div key={menuLabel} className="mb-[18px] last-of-type:mb-0">
                        <NextLink
                          label={menuLabel}
                          href={FooterMenuRoutes[menuLabel.toUpperCase() as keyof typeof FooterMenuRoutes] || '#'}
                          className="tracking-normal text-[14px]"
                          variant="underline"
                        />
                      </div>
                    ))}
                </div>
              ))}
              <div>
                <Group spacing="0.5rem">
                  <GiftCardIcon height={30} width={30} />
                  <NextLink
                    label="Gift Cards"
                    href="/products/gift-card/"
                    variant="underline"
                    className="tracking-normal"
                    bold
                  />
                </Group>
              </div>
            </div>
          </Container>
        </div>
        <div
          className='border-t-[0.6px] border-b-[0.6px] border-black'
        >
          <div className="bg-brand-lightpink">
            <CountrySelector className='px-6 py-6' />
          </div>
        </div>
        <div className="bg-brand-lightpink">
          <Container size="xl" py="xl">
            <Group position="center">{socialLinks}</Group>
          </Container>
        </div>
        <div className="bg-brand-lighestpink border-t-[0.6px] border-black">
          <Container size="xl" pb="1rem">
            <div className='flex flex-col items-center justify-center gap-4'>
              <Logo height={45} width={100} />
              <Group mt="auto" spacing="xs">
                <NextLink label="Terms & Conditions" href="/pages/terms-and-conditions/" variant="underline" />
                <Divider orientation="vertical" color="black" />
                <NextLink label="Privacy Policy" href="/pages/privacy-policy/" variant="underline" />
              </Group>
              <p className='text-xs'>© {setLocaleYear()} Hello Molly. All Rights Reserved</p>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default FooterMenu;
